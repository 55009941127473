body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header Styles */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 5%;
  background-color: #ffffff;
}

.logo {
  font-family: 'Script MT', cursive;
  font-size: 24px;
  color: #000;
}

nav ul {
  list-style-type: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

nav ul li a {
  text-decoration: none;
  color: #333;
  font-size: 14px;
}

/* Hero Section Styles */
.hero-section {
  background-color: #af4949;
  background-size: cover;
  background-position: center;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5%;
  color: #333;
}

.hero-content {
  text-align: center;
}

.hero-content h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.hero-content h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.cta-button {
  display: inline-block;
  background-color: #b8aa8d;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
}

/* Clinic Introduction Styles */
.clinic-introduction {
  text-align: center;
  padding: 50px 0;
  background-color: #f5f5dc;
}

.clinic-introduction .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.clinic-introduction h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.clinic-introduction h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.clinic-introduction p {
  font-size: 16px;
  max-width: 600px;
  margin: 0 auto 20px;
}

.learn-more-btn {
  background-color: #b8aa8d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 30px;
}

.intro-video {
  max-width: 750px;
  margin: 0 auto;
}

.intro-video iframe {
  max-width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}

/* Welcome Section Styles */
.welcome-section {
  background-color: #f5f5dc;
  padding: 50px 0;
  text-align: center;
}

.welcome-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Google Reviews Styles */
.reviews-section {
  padding: 50px 0;
  text-align: center;
}

.reviews-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.review-card {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 5px;
}

/* Footer Styles */
footer {
  background-color: #333;
  color: #ffffff;
  padding: 30px 0;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  header {
    flex-direction: column;
    align-items: flex-start;
  }

  nav ul {
    flex-direction: column;
    margin-top: 1rem;
  }

  .hero-section {
    justify-content: center;
    text-align: center;
    padding-right: 0;
  }

  .hero-content {
    text-align: center;
  }

  .reviews-grid {
    grid-template-columns: 1fr;
  }

  .clinic-introduction h1 {
    font-size: 28px;
  }

  .clinic-introduction h2 {
    font-size: 20px;
  }
  /* Divider */
.section-divider {
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 40px auto;
  width: 100%;
  max-width: 800px;
}
}